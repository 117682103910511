import Api from "../../Api";


const END_POINT_ALLCATEGORIAS = 'user/products/product-categories/all';
const END_POINT_CREATECATEGORIA = 'user/products/product-categories/create';
const END_POINT_UPDATECATEGORIA = 'user/products/product-categories/update';
const END_POINT_DELETECATEGORIA = 'user/products/product-categories/delete';

export default {
    GetAllCategorias(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLCATEGORIAS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreateCategoria(data, auth) {
        return Api.post(END_POINT_CREATECATEGORIA, {
            'name': data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateCategoria(data, auth) {
        return Api.patch(END_POINT_UPDATECATEGORIA, {
            'id': data.id,
            'name': data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByCategoria(id, auth) {
        return Api.delete(END_POINT_DELETECATEGORIA + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}