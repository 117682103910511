import Api from "../../Api";


const END_POINT_ALLPRODUCTS = 'user/products/all';
const END_POINT_CREATEPRODUCT = 'user/products/create';
const END_POINT_UPDATEPRODUCT = 'user/products/update';
const END_POINT_DELETEPRODUCT = 'user/products/delete';

export default {
    GetAllProductos(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLPRODUCTS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreateProducto(data, auth) {
        return Api.post(END_POINT_CREATEPRODUCT, {
            'codigo': data.cod_macro,
            'descripcion': data.descripcion,
            'product_family_id': data.familia,
            'product_unit_id': data.unidad,
            'product_category_id': data.categoria,
            'peso': data.peso_teorico,
            'maximo': data.maximo,
            'minimo': data.minimo
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateProducto(data, auth) {
        return Api.patch(END_POINT_UPDATEPRODUCT, {
            'id': data.id,
            'codigo': data.cod_macro,
            'descripcion': data.descripcion,
            'product_family_id': data.familia,
            'product_unit_id': data.unidad,
            'product_category_id': data.categoria,
            'peso': data.peso_teorico,
            'maximo': data.maximo,
            'minimo': data.minimo
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByProducto(id, auth) {
        return Api.delete(END_POINT_DELETEPRODUCT + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}