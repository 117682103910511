<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Productos"
      subtitle="| Administrar Productos"
      class="heading-block"
    >
      <template #extra>
        <div class="mt-5 mt-sm-0 ml-sm-3">
          <b-button
            variant="alt-primary"
            class=""
            href="javascript:void(0)"
            v-click-ripple
            v-b-popover.hover.bottom="'Alta de un Producto'"
            @click="ShowModalProducto()"
            style="width: 200px"
          >
            <i class="fa fa-plus-square mr-1"></i> Nuevo Producto
          </b-button>
          <!-- Small Block Modal -->
          <b-modal
            id="modal-productos"
            size="lg"
            body-class="p-0"
            hide-footer
            hide-header
          >
            <div
              class="block block-rounded block-themed block-transparent mb-0"
            >
              <div class="block-header bg-primary-dark">
                <h3 class="block-title">
                  <i class="fa fa-user mr-1"></i> Alta de Productos
                </h3>
                <div class="block-options">
                  <button
                    type="button"
                    class="btn-block-option"
                    @click="ShowModalProducto()"
                  >
                    
                  </button>
                  <i style="cursor:pointer;" class="fa fa-fw fa-times" @click="$bvModal.hide('modal-productos')"></i>
                </div>
              </div>
              <div class="block-content font-size-sm">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-row class="ml-4 mt-3">
                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          # Código <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="no_cliente"
                          name="no_cliente"
                          placeholder="-"
                          size="sm"
                          v-model="form.cod_macro"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="ml-4 mt-1">
                        <b-col sm="12">
                          <b-form-group label-for="comentario">
                            <template #label>
                              Descripción
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="no_cliente"
                                name="no_cliente"
                                placeholder="-"
                                size="sm"
                                v-model="form.descripcion"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                  </b-row>
                  <b-row class="ml-4 mt-3">
                        <b-col sm="4">
                        <b-form-group label-for="no_cliente">
                            <template #label>
                            Familia <span class="text-danger">*</span>
                            </template>
                           <select  v-model="form.familia" class="form-control">
                              <option :value="data.id" v-for="data in familias" :key="data.id">{{ data.name }}</option>
                           </select>
                        </b-form-group>
                        </b-col>

                        <b-col sm="4">
                        <b-form-group label-for="no_cliente">
                            <template #label>
                            Unidad <span class="text-danger">*</span>
                            </template>
                            <select  v-model="form.unidad" class="form-control">
                              <option :value="data.id" v-for="data in unidades" :key="data.id">{{ data.name }}</option>
                           </select>
                        </b-form-group>
                        </b-col>

                        <b-col sm="4">
                        <b-form-group label-for="no_cliente">
                            <template #label>
                            Categoria <span class="text-danger">*</span>
                            </template>
                            <select  v-model="form.categoria" class="form-control">
                                <option :value="data.id" v-for="data in categorias" :key="data.id">{{ data.name }}</option>
                           </select>
                        </b-form-group>
                        </b-col>

                        <b-col sm="4">
                        <b-form-group label-for="no_cliente">
                            <template #label>
                            Peso Teórico <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="no_cliente"
                            name="no_cliente"
                            placeholder="-"
                            size="sm"
                            v-model="form.peso_teorico"
                            ></b-form-input>
                        </b-form-group>
                        </b-col>
                   </b-row>
                   <b-row class="ml-4 mt-3">
                        <b-col sm="4">
                        <b-form-group label-for="no_cliente">
                            <template #label>
                            Máximo <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="no_cliente"
                            name="no_cliente"
                            placeholder="-"
                            size="sm"
                            v-model="form.maximo"
                            ></b-form-input>
                        </b-form-group>
                        </b-col>

                        <b-col sm="4">
                            <b-form-group label-for="no_cliente">
                                <template #label>
                                Mínimo <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="no_cliente"
                                name="no_cliente"
                                placeholder="-"
                                size="sm"
                                v-model="form.minimo"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                   </b-row>
                  <br />
                </form>
              </div>
              <div
                class="block-content block-content-full text-right border-top"
              >
                <b-button
                  variant="alt-primary"
                  class="mr-1"
                  @click="$bvModal.hide('modal-productos')"
                  >Cerrar</b-button
                >
                <template v-if="edit == false">
                    <b-button variant="primary" @click="CreateProducto()">Guardar</b-button>
                </template>
                <template v-else> 
                    <b-button variant="danger" @click="UpdateProducto()">Actualizar</b-button>
                </template>

              </div>
            </div>
          </b-modal>
          <!-- END Small Block Modal -->
        </div>
      </template>
    </base-page-heading>

    <div>
      <base-block rounded title="">
        <b-row>
          <b-col sm="6" xl="2">
            <b-dropdown
              id="dropdown-default-light"
              variant="light"
              text="Acciones agrupadas"
            >
              <b-dropdown-item class="font-size-sm"
                >Habilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm"
                >Deshabilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item class="font-size-sm"
                >Eliminar Seleccion</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" class="ml-3">
            <!-- Form Inline - Alternative Style -->
            <b-form inline>
              <b-form-input
                type="search"
                class="form-control-alt mr-sm-2 mb-sm-0"
                id="example-if-email2"
                name="example-if-email2"
                placeholder="Busqueda"
                v-model="filter"
              ></b-form-input>
            </b-form>
            <!-- END Form Inline - Alternative Style -->
          </b-col>

          <b-col>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="float-right"
              right
            >
              <template #button-content>
                <i class="si si-settings"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="/clients.csv" download
                >Exportar Clientes a Excel</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="mr-1 float-right"
              right
            >
              <template #button-content>
                <i class="fas fa-fw fa-filter"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Nombre</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Departamento</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <br />
        <b-overlay :show="showoverlay" rounded="sm">
            <div class="table-responsive">
              <b-table style="cursor: pointer;" :items="productos" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" responsive striped hover >
                  <template #cell(actions)="row" >
                      <b-button size="sm" @click="EditProducto(row.item)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-pencil-alt"></i>
                      </b-button>
                      <b-button size="sm" @click="DeleteProducto(row.item.id)"  class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-times"></i>
                      </b-button>
                  </template>
              </b-table>
            </div>
        </b-overlay>
        <b-row>
            <b-col sm="7" md="2" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
        </b-row>
      </base-block>
    </div>
  </div>
</template>

<script>
import Productos from "../../../api/admin/productos/Productos";
import Familia from "../../../api/admin/productos/Familia";
import Unidades from "../../../api/admin/productos/Unidades";
import Categorias from "../../../api/admin/productos/Categorias";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      productos: [],
      familias:[],
      unidades:[],
      categorias:[],
      filter: null,
      timeout: null,
      edit : false,
      showoverlay:false,
      fields: [
        {key: 'codigo',sortable: true, label: 'CÓDIGO MACRO'},
        {key: 'descripcion',sortable: true, label: 'DESCRIPCIÓN'},
        {key: 'product_family', formatter: value => {
              return value.name
          },sortable: true, label: 'FAMILIA'},
        {key: 'product_unit', formatter: value => {
              return value.name
          },sortable: true, label: 'UNIDAD'},
        {key: 'product_category', formatter: value => {
              return value.name.length ? value.name : 'No especificado'
          },sortable: true, label: 'CATEGORIA'},
        {key: 'peso',sortable: true, label: 'PESO TEORICO'},
        {key: 'maximo',sortable: true, label: 'MÁXIMO'},
        {key: 'minimo',sortable: true, label: 'MÍNIMO'},
        {key: 'actions',sortable: true, label: 'ACCIONES'},
      ],
      form: {
        id:"",
        cod_macro: "",
        descripcion:"",
        familia:"",
        unidad:"",
        categoria:"",
        peso_teorico:"",
        maximo:"",
        minimo:"",
      },
      errors:[],
    };
  },
  computed: {
    totalRows() {
      return this.productos.length
    }
  },
  methods: {

    AllProductos() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      Productos.GetAllProductos(auth)
        .then((response) => {
          this.productos = response.data;
          this.showoverlay = false;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    AllFamilias() {
      let auth = JSON.parse(localStorage.autentication);
      Familia.GetAllFamilias(auth)
        .then((response) => {
          this.familias = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    AllUnidades() {
      let auth = JSON.parse(localStorage.autentication);
      Unidades.GetAllUnidades(auth)
        .then((response) => {
          this.unidades = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    AllCategorias() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      Categorias.GetAllCategorias(auth)
        .then((response) => {
          this.categorias = response.data;
          this.showoverlay = false;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    CreateProducto() {
      this.$bvModal.hide("modal-productos");
      if (this.form.familia && this.form.descripcion ) {
          this.showoverlay = true;
          let auth = JSON.parse(localStorage.autentication);
          Productos.PostCreateProducto(this.form, auth)
            .then((response) => {
              this.AllProductos();
              this.showoverlay = false;
              Swal.fire({
                title: "Genial!",
                text: response.data.message,
                icon: "success",
              });
              this.form = {
                    id:"",
                    cod_macro: "",
                    descripcion:"",
                    familia:"",
                    unidad:"",
                    categoria:"",
                    peso_teorico:"",
                    maximo:"",
                    minimo:"",
              };
              
            })
            .catch((error) => {
              const obj = error.response.data.errors;
              Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
              );
            });
      }else{
         this.errors = []
          if (!this.form.familia) {
              let me=this
              me.errors.push('Seleccione una familia')
          }   
          if (!this.form.descripcion) {
              let me=this
              me.errors.push('Descripcion del producto requerido')
          }          
          Swal.fire({
            title: "Corrija los siguientes errores",
            icon: "error",
            html: this.errors
                .map(item => `<p> <li>${item}</li></p>`)
                .join('')
          });
      }
    },

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },

    ShowModalProducto() {
      this.form = {
        id:"",
        cod_macro: "",
        descripcion:"",
        familia:"",
        unidad:"",
        categoria:"",
        peso_teorico:"",
        maximo:"",
        minimo:"",
      };
      this.$bvModal.show("modal-productos");
      this.edit = false;
    },

    EditProducto(data) {
      this.$bvModal.show("modal-productos");
      this.edit = true;
      this.form = {
        id: data.id,
        cod_macro : data.codigo,
        descripcion:data.descripcion,
        familia : data.product_family.id,
        unidad : data.product_unit.id,
        categoria: data.product_category.id,
        peso_teorico : data.peso,
        maximo: data.maximo,
        minimo : data.minimo,
      };
    },

    UpdateProducto(){
        this.$bvModal.hide("modal-productos");
        this.showoverlay = true;
        let auth = JSON.parse(localStorage.autentication);
        Productos.UpdateProducto(this.form, auth)
        .then((response) => {
          this.AllProductos();
          this.showoverlay = false;
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
            this.form = {
                id:"",
                cod_macro: "",
                descripcion:"",
                familia:"",
                unidad:"",
                categoria:"",
                peso_teorico:"",
                maximo:"",
                minimo:"",
            };     
        })
        .catch((error) => {
          Swal.fire({
            title: "Que mal!",
            text: error.message,
            icon: "error",
          });
        });
    },

    DeleteProducto(id){
        Swal.fire({
            title: "¿Esta seguro de eliminar el registro?",
            text: "¿Eliminar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        })
        .then(resultado => {
            if (resultado.value) {
                 this.showoverlay = true;
                let auth = JSON.parse(localStorage.autentication);
                Productos.DeleteByProducto(id, auth)
                .then((response) => {
                  this.AllProductos();
                  this.showoverlay = false;
                  Swal.fire({
                    title: "Genial!",
                    text: response.data.message,
                    icon: "success",
                  });
                  
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Que mal!",
                    text: error.message,
                    icon: "error",
                  });
                });
            } else {
                // Dijeron que no
                console.log("*NO se elimina la venta*");
            }
        });

        
    },
   

  },

  mounted() {
    let me = this;    
    me.AllProductos();
    me.AllFamilias();
    me.AllUnidades();
    me.AllCategorias();
  },
};
</script>

<style scoped>
.heading-block {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 60px;
  z-index: 1;
}
.scrollable {
  overflow-y: auto;
  max-height: 280px;
}
</style>
